import React from 'react';

import Timeline from './Timeline.js';
import TimelineItem from './TimelineItem.js';

import './Experiences.css';

const Experiences = () => {
    const timelineElements = [
    <TimelineItem
        key="001"
        dateText="08/2023 – Aujourd'hui"
        style={{ color: '#ffcc00' }}
        dateInnerStyle={{ background: '#ffcc00', color: '#000' }}
        bodyContainerStyle={{
        background: '#ddd',
        padding: '20px',
        borderRadius: '8px',
        boxShadow: '0.5rem 0.5rem 2rem 0 rgba(0, 0, 0, 0.2)',
        }}
    >
        <h3>Alternance chez InfleXsys</h3>
        <h4>Participation au développement (fonctionnalités, correctifs, adaptatifs et 
            évolutions) sur les applications mobiles de différents clients</h4>
        <p>
        Après mon stage chez InfleXsys, j'ai poursuivi avec une alternance qui est 
        actuellement en cours. Cette alternance dure 11 mois, débutant en septembre 
        2023 et se terminant en juillet 2024. Pendant cette période, j'ai continué 
        à travailler sur le projet auquel j'avais contribué lors de mon stage, qui 
        consistait en le développement côté serveur d'une application de maintenance 
        pour des machines biomédicales. 
        </p>
        <p>
        Par la suite, j'ai été chargé du développement côté serveur en Java et Protobuf 
        d'une nouvelle application. Cette dernière vise à faciliter la collaboration 
        entre les agences et les entreprises en les aidant à trouver efficacement des 
        intérimaires correspondant à leurs besoins. J'ai également acquis de 
        l'expérience dans l'utilisation de technologies telles que Docker et SQL.
        </p>
    </TimelineItem>,
    <TimelineItem
        key="002"
        dateText="09/2021 – Aujourd'hui"
        style={{ color: '#e86971' }}
    >
        <h3>Employé de l'Union Bordeaux Bègles</h3>
        <h4>Affecté à la buvette</h4>
        <p>
        En tant qu'employé à la buvette de l'Union Bordeaux Bègles, 
        j'ai été chargé du service clientèle lors des événements sportifs, 
        de l'encaissement des paiements, ainsi que de la gestion rigoureuse 
        de l'inventaire en début de match pour garantir des stocks adéquats. 
        Cette expérience m'a permis de développer mes compétences en interaction 
        avec la clientèle, tout en démontrant ma capacité à travailler efficacement 
        en équipe et à contribuer à l'atmosphère dynamique et 
        conviviale des événements sportifs.
        </p>
    </TimelineItem>,
    <TimelineItem
        key="003"
        dateText="09/2021 – Aujourd'hui"
        style={{ color: '#ffcc00' }}
        dateInnerStyle={{ background: '#ffcc00', color: '#000' }}
        bodyContainerStyle={{
        background: '#ddd',
        padding: '20px',
        borderRadius: '8px',
        boxShadow: '0.5rem 0.5rem 2rem 0 rgba(0, 0, 0, 0.2)',
        }}
    >
        <h3>Bachelor universitaire de technologie (BUT) </h3>
        <h4>Actuellement en 3ème année</h4>
        <p>
        Au cours de mon parcours en Bachelor universitaire de technologie, j'ai développé 
        un large éventail de compétences. En informatique, j'ai acquis une solide expertise 
        dans la programmation, la conception de logiciels et l'analyse des données, tout 
        en explorant des technologies émergentes telles que l'intelligence artificielle 
        et la cybersécurité.
        </p>
        <p>
        Parallèlement, j'ai eu l'opportunité d'approfondir mes compétences en gestion de projet 
        en participant à des projets collaboratifs avec mes pairs. Ces expériences m'ont permis 
        d'explorer et de mettre en pratique différentes méthodes de management, telles que le 
        cycle en V et les méthodes agiles.
        De plus, j'ai souvent adopté la méthode Scrum, une approche agile qui favorise la 
        flexibilité, la collaboration et l'itération rapide.
        </p>
        <p>
        La communication joue également un rôle central dans mon cursus, que ce soit à 
        travers la présentation de projets, la rédaction de rapports techniques ou la 
        collaboration au sein d'équipes multidisciplinaires. J'ai appris à articuler mes 
        idées de manière claire et concise, et à travailler efficacement avec des collègues 
        aux compétences variées.
        </p>
        <p>
        Les mathématiques constituent un pilier fondamental de ma formation, me permettant 
        d'aborder des problèmes complexes avec rigueur. Que ce soit dans l'analyse de données, 
        la modélisation ou l'optimisation, j'ai développé une approche méthodique qui m'aide à 
        résoudre des défis de manière efficace.
        </p>
    </TimelineItem>,
    <TimelineItem
        key="004"
        dateText="04/2023 – 06/2023"
        style={{ color: '#e86971' }}
    >
        <h3>Stage chez InfleXsys</h3>
        <h4>Participation au développement côté serveur d'une application de maintenance de 
            machines biomédicales</h4>
        <p>
        Pendant ma deuxième année de BUT Informatique, j'ai réalisé un stage de 10 semaines 
        chez InfleXsys en 2023. Au sein de cette entreprise, j'ai été impliqué dans le 
        développement côté serveur d'une application de maintenance destinée aux machines 
        biomédicales. Mon rôle consistait principalement à utiliser les langages Java et Thrift, 
        ainsi qu'à travailler avec HTML et CSS de manière plus marginale. Ce stage m'a permis de 
        collaborer au sein d'une équipe dynamique comptant une dizaine de membres, où nous avons 
        appliqué des méthodes de gestion de projet agiles, notamment la méthodologie Scrum.
        </p>
    </TimelineItem>,
    <TimelineItem
        key="005"
        dateText="09/2018 – 06/2021"
        style={{ color: '#ffcc00' }}
        dateInnerStyle={{ background: '#ffcc00', color: '#000' }}
        bodyContainerStyle={{
        background: '#ddd',
        padding: '20px',
        borderRadius: '8px',
        boxShadow: '0.5rem 0.5rem 2rem 0 rgba(0, 0, 0, 0.2)',
        }}
    >
        <h3>Lycée Fernand Daguin</h3>
        <p>
        Au lycée, j'ai décroché mon baccalauréat avec mention Bien. Mes spécialités en Terminale 
        étaient Numérique et Sciences Informatiques (NSI) ainsi que Mathématiques, tandis 
        qu'en Première, j'ai exploré le domaine de la Physique-Chimie. 
        De plus, j'ai enrichi mes connaissances en choisissant l'option Mathématiques Expertes 
        en Terminale, renforçant ainsi ma maîtrise des concepts mathématiques avancés.
        </p>
    </TimelineItem>,
    ];

    return (
        <section id="experiences">
            <Timeline lineColor="#ddd">{timelineElements}</Timeline>
        </section>
    );
};

export default Experiences;
