import React from 'react';
import './Accueil.css';
import photo from './pictures/photo.png';

function Accueil() {
  return (
    <section id="accueil" className="container">
      <div className="image">
        <img src={photo} alt="" />
      </div>
      <div className="texte">
        <p>Bienvenue sur mon portfolio !</p>
        <p>Je suis actuellement développeur en alternance chez InfleXsys, au sein du Bachelor Universitaire de 
          Technologie (BUT) Informatique de Bordeaux. Passionné par les technologies informatiques, j'ai choisi 
          ce parcours pour approfondir mes connaissances et acquérir une expérience pratique dans le domaine. Mon 
          objectif est d'utiliser mes compétences et de les développer davantage à travers des projets concrets et 
          des collaborations enrichissantes.</p>
      </div>
    </section>
  );
}

export default Accueil;
