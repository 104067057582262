import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import './Contact.css';

const Contact = () => {

  const form = useRef();

  const handleInputChange = (e) => {
    const input = e.target;
    input.dataset.filled = !!input.value.trim();
  };

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs.sendForm(
      process.env.REACT_APP_SERVICE_ID,
      process.env.REACT_APP_TEMPLATE_ID,
      form.current,
      process.env.REACT_APP_PUBLIC_KEY
    ).then(
      (result) => {
        alert('Message sent successfully...');
        console.log(result.text);
      },
      (error) => {
        console.log(error.text);
      }
    );
  };

  return (
    <section id="contact">
      <div className="containerContact">
        <div className="text">
          Contactez-moi !
        </div>
        <form className='cf' ref={form} onSubmit={sendEmail}>
          <div className="form-row">
              <div className="input-data">
                <input type="text" name="from_name" required />
                <div className="underline"></div>
                <label htmlFor="from_name">Nom</label>
              </div>
              <div className="input-data">
                <input type="text" name="subject" required />
                <div className="underline"></div>
                <label htmlFor="subject">Objet du mail</label>
              </div>
          </div>
          <div className="form-row">
              <div className="input-data">
                <input type="email" name="from_email" required onInput={handleInputChange} />
                <div className="underline"></div>
                <label htmlFor="from_email">Adresse e-mail</label>
              </div>
          </div>
          <div className="form-row">
            <div className="input-data textarea">
              <textarea rows="8" cols="80" name="message" required></textarea>
              <br />
              <div className="underline"></div>
              <label htmlFor="message">Message</label>
              <br />
              <div className="form-row submit-btn">
                <div className="input-data">
                    <div className="inner"></div>
                    <input type="submit" value="Envoyer" />
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </section>
  );
};

export default Contact;
