import React from 'react';
import Header from './Header';
import Accueil from './Accueil';
import Competences from './Competences';
import Experiences from './Experiences';
import Portfolio from './Portfolio';
import Contact from './Contact';
import Footer from './Footer';

function App() {
  return (
    <div className="App">
      <Header />
      <Accueil />
      <Competences />
      <Experiences />
      <Portfolio />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
