import React from 'react';
import './BurgerMenu.css';

function BurgerMenu() {
  const closeMenu = () => {
    document.querySelector('.toggler').checked = false;
  };

  return (
    <div className="menu-wrap">
        <input type="checkbox" className="toggler" />
        <div className="hamburger">
          <div></div>
        </div>
        <div className="menu">
            <div>
                <div>
                    <ul>
                      <li><a href="#accueil" onClick={closeMenu}>Accueil</a></li>
                      <li><a href="#competences" onClick={closeMenu}>Compétences</a></li>
                      <li><a href="#experiences" onClick={closeMenu}>Expériences</a></li>
                      <li><a href="#portfolio" onClick={closeMenu}>Portfolio</a></li>
                      <li><a href="#contact" onClick={closeMenu}>Contact</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
  );
}

export default BurgerMenu;
