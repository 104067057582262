import React, { useState } from 'react';
import { FaDownload, FaExternalLinkAlt } from 'react-icons/fa';
import './Portfolio.css';

import series from './pictures/series.jpg';
import punch from './pictures/punch.png';
import rtSport from './pictures/sport.jpg';
import reseauImage from './pictures/reseau.jpeg';
import airbnbImage from './pictures/airbnb.jpg';
import empreinteCarboneImage from './pictures/empreinte-carbone.jpeg';

import woodeo from './pdf/woodeo.pdf';
import rtSportGestion from './pdf/rt-sport-gestion.pdf';
import rtSportSite from './pdf/rt-sport-site.pdf';
import reseau from './pdf/reseau.pdf';
import airbnb from './pdf/airbnb.pdf';
import empreinteCarbone from './pdf/visualisation-empreinte-carbone.pdf';

const Portfolio = () => {
  const [isFullStates, setIsFullStates] = useState(Array(6).fill(false));

  const handleCardClick = (index) => {
    const newIsFullStates = [...isFullStates];
    newIsFullStates[index] = !newIsFullStates[index];
    setIsFullStates(newIsFullStates);
  };

  return (
    <section id="portfolio">
      <div className="portfolio-container">
        <div className="card-background">
          <div className={`card-portfolio${isFullStates[0] ? ' full' : ''}`} onClick={() => handleCardClick(0)}>
            <div className="image" style={{ backgroundImage: `url(${series})` }}></div>
            <div className="description">
              <div className="text-1">Woodeo, un site pour noter vos séries préférées !</div>
              <div className="links">
                <a href={woodeo} target="_blank" rel="noopener noreferrer">
                  <FaDownload /> Présentation
                </a>
                <a href="https://woodeo.valentinraillard.fr/" target="_blank" rel="noopener noreferrer">
                  <FaExternalLinkAlt /> Visiter le site
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="card-background">
          <div className={`card-portfolio${isFullStates[1] ? ' full' : ''}`} onClick={() => handleCardClick(1)}>
            <div className="image" style={{ backgroundImage: `url(${punch})` }}></div>
            <div className="description">
              <div className="text-1">Punch est une application mobile de partage de musiques, d'albums et d'artistes réalisée avec Flutter et Firebase.</div>
            </div>
          </div>
        </div>
        <div className="card-background">
          <div className={`card-portfolio${isFullStates[2] ? ' full' : ''}`} onClick={() => handleCardClick(2)}>
            <div className="image" style={{ backgroundImage: `url(${rtSport})` }}></div>
            <div className="description">
              <div className="text-1">RT-Sport, un site de vente de matériels de musculation.</div>
              <div className="links">
                <a href={rtSportGestion} target="_blank" rel="noopener noreferrer">
                  <FaDownload /> Présentation gestion
                </a>
                <a href={rtSportSite} target="_blank" rel="noopener noreferrer">
                  <FaDownload /> Présentation site
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="card-background">
          <div className={`card-portfolio${isFullStates[3] ? ' full' : ''}`} onClick={() => handleCardClick(3)}>
            <div className="image" style={{ backgroundImage: `url(${reseauImage})` }}></div>
            <div className="description">
              <div className="text-1">Réseau : configuration d'un serveur DNS.</div>
              <div className="links">
                <a href={reseau} target="_blank" rel="noopener noreferrer">
                  <FaDownload /> Présentation
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="card-background">
          <div className={`card-portfolio${isFullStates[4] ? ' full' : ''}`} onClick={() => handleCardClick(4)}>
            <div className="image" style={{ backgroundImage: `url(${airbnbImage})` }}></div>
            <div className="description">
              <div className="text-1">Une application de location de biens immobiliers développée en Java.</div>
              <div className="links">
                <a href={airbnb} target="_blank" rel="noopener noreferrer">
                  <FaDownload /> Présentation
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="card-background">
          <div className={`card-portfolio${isFullStates[5] ? ' full' : ''}`} onClick={() => handleCardClick(5)}>
            <div className="image" style={{ backgroundImage: `url(${empreinteCarboneImage})` }}></div>
            <div className="description">
              <div className="text-1">Un outil de visualisation de l'empreinte carbone.</div>
              <div className="links">
                <a href={empreinteCarbone} target="_blank" rel="noopener noreferrer">
                  <FaDownload /> Présentation
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Portfolio;
