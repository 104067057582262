import React, { useState } from 'react';
import './Competences.css';
import closeIcon from './pictures/close.svg';
import java from './pictures/java.png';
import react from './pictures/react.png';
import js from './pictures/js.png';
import html from './pictures/html.png';
import css from './pictures/css.png';
import c from './pictures/c.png';
import python from './pictures/python.png';
import symfony from './pictures/symfony.png';
import sql from './pictures/sql.png'
import nosql from './pictures/nosql.png';
import flutter from './pictures/flutter.png';
import git from './pictures/git.png';
import github from './pictures/github.png';
import gitlab from './pictures/gitlab.png';
import bootstrap from './pictures/bootstrap.png';

const cardsLanguages = [
  {
    image: java,
    backgroundColor: '#B2DCE5',
    text: "Java est un langage de programmation polyvalent et orienté objet, connu pour sa portabilité, sa fiabilité et sa facilité d'utilisation."
  },
  {
    image: js,
    backgroundColor: '#B2DCE5',
    text: "JavaScript (JS) est le langage de programmation de l'interface web, permettant d'ajouter des fonctionnalités interactives et dynamiques aux pages HTML."
  },
  {
    image: html,
    backgroundColor: '#B2DCE5',
    text: "HTML (HyperText Markup Language) est le langage de balisage standard utilisé pour créer et structurer le contenu des pages Web."
  },
  {
    image: css,
    backgroundColor: '#B2DCE5',
    text: "CSS (Cascading Style Sheets) est un langage de feuilles de style utilisé pour contrôler la mise en forme, la disposition et l'apparence visuelle des éléments sur une page web."
  },
  {
    image: c,
    backgroundColor: '#B2DCE5',
    text: "Le langage C est un langage de programmation impératif simple, performant et avec une grande portabilité. Il est souvent utilisé pour créer des systèmes d'exploitation, des logiciels systèmes et des applications embarquées."
  },
  {
    image: python,
    backgroundColor: '#B2DCE5',
    text: "Python est un langage de programmation polyvalent, simple et puissant. Utilisé pour le développement web, l'analyse de données, l'automatisation et plus encore."
  }
];

const cardsFrameworks = [
  {
    image: react,
    backgroundColor: '#B2DCE5',
    text: "React est une bibliothèque JavaScript populaire pour la construction d'interfaces utilisateur interactives et dynamiques."
  },
  {
    image: flutter,
    backgroundColor: '#B2DCE5',
    text: "Flutter est un framework open-source basé sur le langage Dart, permettant de créer des applications multiplateformes avec une seule base de code."
  },
  {
    image: symfony,
    backgroundColor: '#B2DCE5',
    text: "Symfony est un framework PHP robuste et flexible, utilisé pour développer des applications web complexes."
  },
  {
    image: bootstrap,
    backgroundColor: '#B2DCE5',
    text: "Bootstrap est un framework CSS pour créer des interfaces web réactives et esthétiques."
  }
]

const cardsDatabases = [
  {
    image: sql,
    backgroundColor: '#B2DCE5',
    text: "SQL est un langage utilisé pour gérer et manipuler des bases de données relationnelles de manière efficace."
  },
  {
    image: nosql,
    backgroundColor: '#B2DCE5',
    text: "NoSQL est une approche de gestion de données qui se distingue par sa flexibilité et sa capacité à stocker et traiter de grandes quantités de données, souvent non structurées."
  },
]

const cardsGit = [
  {
    image: git,
    backgroundColor: '#B2DCE5',
    text: "Git est un système de contrôle de version distribué utilisé pour suivre les modifications apportées au code source d'un projet."
  },
  {
    image: github,
    backgroundColor: '#B2DCE5',
    text: "GitHub est une plateforme de développement logiciel basée sur Git, favorisant la collaboration et l'hébergement de projets."
  },
  {
    image: gitlab,
    backgroundColor: '#B2DCE5',
    text: "GitLab est une plateforme de gestion de code source et de collaboration intégrée basée sur Git."
  }
]

const Competences = () => {
  const [openIndexesLanguages, setOpenIndexesLanguages] = useState(Array(cardsLanguages.length).fill(false));
  const [openIndexesFrameworks, setOpenIndexesFrameworks] = useState(Array(cardsFrameworks.length).fill(false));
  const [openIndexesDatabases, setOpenIndexesDatabases] = useState(Array(cardsDatabases.length).fill(false));
  const [openIndexesGit, setOpenIndexesGit] = useState(Array(cardsGit.length).fill(false));

  const handleCardToggleLanguages = (index) => {
    setOpenIndexesLanguages((prevOpenIndexes) => {
      const newState = [...prevOpenIndexes];
      newState[index] = !newState[index];
      return newState;
    });
  };

  const handleCardToggleFrameworks = (index) => {
    setOpenIndexesFrameworks((prevOpenIndexes) => {
      const newState = [...prevOpenIndexes];
      newState[index] = !newState[index];
      return newState;
    });
  };

  const handleCardToggleDatabases = (index) => {
    setOpenIndexesDatabases((prevOpenIndexes) => {
      const newState = [...prevOpenIndexes];
      newState[index] = !newState[index];
      return newState;
    });
  };

  const handleCardToggleGit = (index) => {
    setOpenIndexesGit((prevOpenIndexes) => {
      const newState = [...prevOpenIndexes];
      newState[index] = !newState[index];
      return newState;
    });
  };

  return (
    <section id="competences">
      <div className="row">
        <h2>Langages de programmation</h2>
        {cardsLanguages.map((card, index) => (
          <div key={index} className="small-7 medium-5 large-4 column end small-centered card" style={{ backgroundColor: card.backgroundColor }}>
            <div className="card-top">
              <img src={card.image} alt="Card Img" className="card-img" />
            </div>
            <div className="triangle"></div>
            <button id="circleDrop" className="circle-button" onClick={() => handleCardToggleLanguages(index)}>
              <img src={closeIcon} alt="Close" className={openIndexesLanguages[index] ? 'close closeRotate' : 'close'} />
            </button>
            <div className="card-middle" style={{ maxHeight: openIndexesLanguages[index] ? '200px' : '0' }}>
              <h5 className="more">{card.text}</h5>
            </div>
          </div>
        ))}
        <h2>Frameworks</h2>
        {cardsFrameworks.map((card, index) => (
          <div key={index} className="small-7 medium-5 large-4 column end small-centered card" style={{ backgroundColor: card.backgroundColor }}>
            <div className="card-top">
              <img src={card.image} alt="Card Img" className="card-img" />
            </div>
            <div className="triangle"></div>
            <button id="circleDrop" className="circle-button" onClick={() => handleCardToggleFrameworks(index)}>
              <img src={closeIcon} alt="Close" className={openIndexesFrameworks[index] ? 'close closeRotate' : 'close'} />
            </button>
            <div className="card-middle" style={{ maxHeight: openIndexesFrameworks[index] ? '200px' : '0' }}>
              <h5 className="more">{card.text}</h5>
            </div>
          </div>
        ))}
        <h2>Base de données</h2>
        {cardsDatabases.map((card, index) => (
          <div key={index} className="small-7 medium-5 large-4 column end small-centered card" style={{ backgroundColor: card.backgroundColor }}>
            <div className="card-top">
              <img src={card.image} alt="Card Img" className="card-img" />
            </div>
            <div className="triangle"></div>
            <button id="circleDrop" className="circle-button" onClick={() => handleCardToggleDatabases(index)}>
              <img src={closeIcon} alt="Close" className={openIndexesDatabases[index] ? 'close closeRotate' : 'close'} />
            </button>
            <div className="card-middle" style={{ maxHeight: openIndexesDatabases[index] ? '200px' : '0' }}>
              <h5 className="more">{card.text}</h5>
            </div>
          </div>
        ))}
        <h2>Gestionnaire de code</h2>
        {cardsGit.map((card, index) => (
          <div key={index} className="small-7 medium-5 large-4 column end small-centered card" style={{ backgroundColor: card.backgroundColor }}>
            <div className="card-top">
              <img src={card.image} alt="Card Img" className="card-img" />
            </div>
            <div className="triangle"></div>
            <button id="circleDrop" className="circle-button" onClick={() => handleCardToggleGit(index)}>
              <img src={closeIcon} alt="Close" className={openIndexesGit[index] ? 'close closeRotate' : 'close'} />
            </button>
            <div className="card-middle" style={{ maxHeight: openIndexesGit[index] ? '200px' : '0' }}>
              <h5 className="more">{card.text}</h5>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Competences;
