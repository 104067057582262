import React from 'react';
import { FaLinkedin, FaGithub, FaDownload } from 'react-icons/fa';
import './Footer.css';
import cv from './pdf/cv.pdf';

const Footer = () => {
  return (
    <footer>
      <div className="containerFooter">
        <div className="social-icons">
          <a href="https://www.linkedin.com/in/geoffrey-ibos-0414b2253/" target="_blank" rel="noopener noreferrer">
            <FaLinkedin />
          </a>
          <a href="https://github.com/geoffreyibos" target="_blank" rel="noopener noreferrer">
            <FaGithub />
          </a>
          <a href={cv} target="_blank" rel="noopener noreferrer">
            <FaDownload /> Mon CV
          </a>
        </div>
        <div className="footer-text">
          © 2024 Portfolio. Tous droits réservés.
        </div>
      </div>
    </footer>
  );
};

export default Footer;
