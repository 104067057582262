import React, { useState, useEffect } from 'react';
import './Header.css';
import BurgerMenu from './BurgerMenu';

function Header() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <header>
      <div className="container">
        <h1>IBOS Geoffrey</h1>
        {windowWidth <= 1000 ? <BurgerMenu /> : (
          <nav>
            <ul>
              <li><a href="#accueil">Accueil</a></li>
              <li><a href="#competences">Compétences</a></li>
              <li><a href="#experiences">Expériences</a></li>
              <li><a href="#portfolio">Portfolio</a></li>
              <li><a href="#contact">Contact</a></li>
            </ul>
          </nav>
        )}
      </div>
    </header>
  );
}

export default Header;
